import { FC, useRef, useState } from 'react';
import s from './VideoCard.module.scss';

interface VideoCardProps {
    videoSrc: string; // путь к видеофайлу
}

export const VideoCard: FC<VideoCardProps> = ({ videoSrc }) => {
    return (
        <div className={s.wrapper}>
            <div className={s.card}>
                <video
                    src={videoSrc}
                    loop
                    muted
                    controls // Отображает панель инструментов
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                    }}
                />
            </div>
        </div>
    );
};
